































































































































































































































































import Vue, { PropOptions } from 'vue'

import { ValidationProvider } from 'vee-validate'
import { COLUMN_TYPE } from '@locokit/lck-glossary'
import { TranslateResult } from 'vue-i18n'

import { lckServices } from '@/services/lck-api'
import { formulaColumnsNamesToIds, formulaColumnsIdsToNames } from '@/services/lck-utils/formula'
import { transformColumnsType } from '@/services/lck-utils/temporary'
import { createSlug } from '@/services/lck-utils/transformText'

import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import InputSwitch from 'primevue/inputswitch'
import InputNumber from 'primevue/inputnumber'
import Textarea from 'primevue/textarea'
import Checkbox from 'primevue/checkbox'

import DialogForm from '@/components/ui/DialogForm/DialogForm.vue'
import SelectTypeColumn from '@/components/admin/database/SelectTypeColumn/SelectTypeColumn.vue'
import ColumnValidation from '@/components/admin/database/ColumnValidation/ColumnValidation.vue'
import RelationBetweenTablesTypeColumn from '@/views/modals/RelationBetweenTablesTypeColumn.vue'
import LookedUpTypeColumn from '@/views/modals/LookedUpTypeColumn.vue'
import { LckTableColumn, SelectValue, SelectValueWithId } from '@/services/lck-api/definitions'

export default Vue.extend({
  name: 'HandleColumnModal',
  components: {
    'lck-dialog-form': DialogForm,
    'lck-select-type-column': SelectTypeColumn,
    'lck-monaco-editor': () => import(/* webpackChunkName: "lck-monaco-editor" */'@/components/store/MonacoEditor/MonacoEditor.vue'),
    'lck-relation-between-tables-type-column': RelationBetweenTablesTypeColumn,
    'lck-looked-up-type-column': LookedUpTypeColumn,
    'lck-column-validation': ColumnValidation,
    'p-input-text': Vue.extend(InputText),
    'p-textarea': Vue.extend(Textarea),
    'p-dropdown': Vue.extend(Dropdown),
    'p-checkbox': Vue.extend(Checkbox),
    'p-input-switch': Vue.extend(InputSwitch),
    'p-input-number': Vue.extend(InputNumber),
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    tableId: {
      type: String,
      required: true,
    },
    columnToHandle: {
      type: Object,
      required: false,
    } as PropOptions<LckTableColumn>,
    tableColumns: {
      type: Array,
      required: false,
      default: () => [],
    } as PropOptions<LckTableColumn[]>,
  },
  data () {
    return {
      COLUMN_TYPE,
      columnName: null as string|null,
      columnDocumentation: null as string|null|undefined,
      columnSlug: null as string|null,
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      columnValidation: {} as Record<string, any>,
      referenceToHandle: { isActive: false, position: 0 },
      selectedColumnTypeIdToHandle: null as COLUMN_TYPE|null,
      errorHandleColumn: null as string|null,
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      settings: {} as Record<string, any>,
    }
  },
  computed: {
    autogenerateSlug (): null|string {
      if (this.columnName) return createSlug(this.columnName)
      return null
    },
    isSelectColumnType (): boolean {
      return this.selectedColumnTypeIdToHandle === COLUMN_TYPE.SINGLE_SELECT || this.selectedColumnTypeIdToHandle === COLUMN_TYPE.MULTI_SELECT
    },
    columnTypes (): {id: number; description: string|TranslateResult; name: string|TranslateResult}[] {
      return Object.keys(transformColumnsType()).map((key: string) => {
        return ({
          id: COLUMN_TYPE[key as keyof typeof COLUMN_TYPE],
          description: this.$t(`pages.databaseSchema.columnType.${key}.description`),
          name: this.$t(`pages.databaseSchema.columnType.${key}.name`),
        })
      })
    },
    isRelationBetweenTablesType (): boolean {
      return this.selectedColumnTypeIdToHandle === COLUMN_TYPE.RELATION_BETWEEN_TABLES
    },
    isLookedUpType (): boolean {
      return this.selectedColumnTypeIdToHandle === COLUMN_TYPE.LOOKED_UP_COLUMN ||
        this.selectedColumnTypeIdToHandle === COLUMN_TYPE.VIRTUAL_LOOKED_UP_COLUMN
    },
    isFormulaType (): boolean {
      return this.selectedColumnTypeIdToHandle === COLUMN_TYPE.FORMULA
    },
    isBooleanType (): boolean {
      return this.selectedColumnTypeIdToHandle === COLUMN_TYPE.BOOLEAN
    },
  },
  methods: {
    closeHandleColumnModal (reloadParent = false): void {
      this.columnName = null
      this.columnDocumentation = null
      this.columnSlug = null
      this.selectedColumnTypeIdToHandle = null
      this.columnValidation = {}
      this.referenceToHandle = { isActive: false, position: 0 }
      this.settings = {}
      this.$emit('close', reloadParent)
    },
    async confirmHandleColumnModal () {
      try {
        if (this.columnName && this.selectedColumnTypeIdToHandle) {
          if (this.columnToHandle) {
            await lckServices.tableColumn.patch(this.columnToHandle.id, {
              // eslint-disable-next-line @typescript-eslint/camelcase
              table_id: this.tableId,
              text: this.columnName,
              documentation: this.columnDocumentation,
              slug: this.columnSlug,
              reference: this.referenceToHandle.isActive,
              // eslint-disable-next-line @typescript-eslint/camelcase
              reference_position: Number(this.referenceToHandle.position),
              settings: this.getSettings(),
              validation: this.columnValidation,
            } as LckTableColumn)
          } else {
            await lckServices.tableColumn.create({
              // eslint-disable-next-line @typescript-eslint/camelcase
              table_id: this.tableId,
              text: this.columnName,
              documentation: this.columnDocumentation,
              slug: this.autogenerateSlug,
              reference: this.referenceToHandle.isActive,
              // eslint-disable-next-line @typescript-eslint/camelcase
              reference_position: Number(this.referenceToHandle.position),
              // eslint-disable-next-line @typescript-eslint/camelcase
              column_type_id: this.selectedColumnTypeIdToHandle,
              settings: this.getSettings(),
              validation: this.columnValidation,
            } as LckTableColumn)
          }
          this.closeHandleColumnModal(true)
        }
      } catch (errorHandleColumn) {
        if (errorHandleColumn instanceof Error) this.errorHandleColumn = errorHandleColumn.message
      }
    },
    getSettings () {
      if (this.isSelectColumnType || this.isRelationBetweenTablesType || this.isLookedUpType || this.isBooleanType) {
        return this.settings
      }
      if (this.isFormulaType) {
        return this.formulaSettings()
      }
      return {}
    },
    onSelectedColumnTypeTohandleChange () {
      this.settings = {}
    },

    getSelectedColumnTypeDisplayDescription (selectedColumnTypeIdToHandle: number) {
      return this.columnTypes.find((columnType) => columnType.id === selectedColumnTypeIdToHandle)?.description
    },

    selectTypeValuesChange (data: SelectValueWithId[]) {
      let settings = {}
      data.forEach((selectTypeValue) => {
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        const newSelectTypeValue: Record<string, any> = {}
        newSelectTypeValue[selectTypeValue.id] = { ...selectTypeValue } as SelectValue
        delete newSelectTypeValue[selectTypeValue.id].id
        settings = { ...settings, ...newSelectTypeValue }
      })
      this.settings = { values: settings, default: this.settings.default }
    },
    defaultSelectTypeValueIdChange (uuid: string) {
      this.settings.default = uuid
    },
    tableIdChange (uuid: string) {
      this.settings.tableId = uuid
    },
    localFieldIdChange (uuid: string) {
      this.settings.localField = uuid
    },
    foreignFieldIdChange (uuid: string) {
      this.settings.foreignField = uuid
    },
    formulaChange (data: string, validate: Function) {
      validate(data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-unused-expressions
      (this.$refs['vp-column-formula-content'] as any)?.setFlags({
        pristine: false,
        dirty: true,
        touched: true,
        untouched: false,
      })
      this.settings.formula = data
    },
    formulaSettings () {
      return {
        formula: formulaColumnsNamesToIds(this.settings.formula || '', this.tableColumns),
      }
    },
  },
  watch: {
    columnToHandle: function () {
      if (this.columnToHandle) {
        this.columnName = this.columnToHandle.text
        this.columnDocumentation = this.columnToHandle.documentation
        this.columnSlug = this.columnToHandle.slug
        if (Object.prototype.hasOwnProperty.call(this.columnToHandle, 'reference')) {
          this.referenceToHandle.isActive = this.columnToHandle.reference
        }
        if (Object.prototype.hasOwnProperty.call(this.columnToHandle, 'reference_position')) {
          this.referenceToHandle.position = this.columnToHandle.reference_position
        }
        this.selectedColumnTypeIdToHandle = this.columnToHandle.column_type_id
        this.columnValidation = this.columnToHandle.validation || {}
        // Set formula column
        if (this.isFormulaType) {
          this.settings.formula = formulaColumnsIdsToNames(
            this.columnToHandle.settings?.formula || '',
            this.tableColumns,
          )
        } else {
          this.settings = this.columnToHandle.settings
        }
      }
      this.errorHandleColumn = null
    },
  },
})
